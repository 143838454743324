/* eslint-disable react/no-array-index-key */
/* global tw */
import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

const Post = styled('div')`
  ${tw('relative mb-6')};
`

const FluidImage = styled(Img)`
  ${tw('w-full my-4 rounded shadow-lg')};
  height: 300px;
`

const Title = styled('h2')`
  ${tw('absolute p-5 my-0 bg-yellow-500 rounded shadow')};
  max-width: calc(100% - 60px);
  left: 30px;
  top: 30px;
`

const PublishDate = styled('h4')`
  ${tw('absolute p-2 my-0 bg-white rounded shadow')};
  right: 10px;
  top: -20px;
`

const Tags = styled('div')`
  ${tw('hidden lg:block absolute')};
  left: 30px;
  bottom: 15px;
`

const Tag = styled('span')`
  ${tw('py-1 px-2 mx-1 bg-blue-800 text-white text-sm font-semibold rounded-full shadow')};
`

const Attribution = styled('h6')`
  ${tw('absolute px-1 my-0 bg-gray-800 text-white rounded shadow')};
  right: 10px;
  bottom: 10px;
`

const BlogPage = () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { draft: { eq: false } } }
        ) {
          edges {
            node {
              id
              excerpt(pruneLength: 250)
              frontmatter {
                path
                date(formatString: "MMMM DD, YYYY")
                title
                tags
                attribution
              }
              featuredImage {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <>
        {data.allMarkdownRemark.edges
          .filter((edge) => !!edge.node.frontmatter.date)
          .map(({ node }) => (
            <Link key={node.id} to={node.frontmatter.path}>
              <Post key={node.id}>
                <FluidImage fluid={node.featuredImage.childImageSharp.fluid} />
                <Title>{node.frontmatter.title}</Title>
                <PublishDate>{node.frontmatter.date}</PublishDate>
                <Tags>
                  {node.frontmatter.tags.map((tag, tagIndex) => (
                    <Tag key={tagIndex}>{tag}</Tag>
                  ))}
                </Tags>
                {node.frontmatter.attribution && <Attribution>Photo by {node.frontmatter.attribution}</Attribution>}
              </Post>
            </Link>
          ))}
      </>
    )}
  />
)

export default BlogPage
/* eslint-enable react/no-array-index-key */
